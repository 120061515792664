.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.buttonAction {
  @apply flex items-center justify-end mb-[18px];
}

.statisticTable {
  @apply flex-1 overflow-y-hidden z-0;
}

.paginationTable {
  @apply flex items-center justify-center mt-6;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}
