.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.detailClientWrap {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.detailClientBody {
  @apply w-full h-full overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.viewInfoWrap {
  @apply flex flex-col w-full gap-3;
}

.viewContentUp {
  @apply flex w-full overflow-y-auto gap-[30px];
}

.viewInfoLeft {
  @apply flex flex-col gap-4 flex-grow max-w-[calc(100%-205px)];
}

.viewAvatarRight {
  @apply flex w-[175px] h-[175px] items-center justify-end;
}

.viewInfoContent {
  @apply flex flex-col gap-3 w-full;
}

.additionalContactWrap {
  @apply clear-both pt-9 flex flex-col gap-5;
}

.additionalContactTitle {
  @apply text-14-500-17 text-primary-zinc-800 border-b pb-4;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.contentAdditionalContact {
  @apply flex flex-col gap-4;
}

.contactAdditionalHeader {
  @apply grid grid-cols-12 gap-5 border-b pb-3;
  border-color: rgba(9, 9, 11, 0.05);
}

.titleContactHeader {
  @apply col-span-3 text-14-400-normal text-primary-neutral-400;
}

.additionalContactDetailItem {
  @apply grid gap-5 grid-cols-12 border-b pb-4;
  border-color: rgba(9, 9, 11, 0.05);

  &.noBorder {
    @apply border-b-0 pb-0;
  }
}

.titleContact {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-3;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-full;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-full;
}
