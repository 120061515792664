.jobPurchasesReportPage {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.statisticAccordion {
  @apply flex-1 flex flex-col overflow-auto z-0 w-full h-full;
}

.pagePagination {
  @apply mt-6;
}

.noDataAvailable {
  @apply flex text-14-400-normal justify-center items-center h-full flex-1;
}
