// Accordion
.accordionContainer {
  @apply border border-primary-gray-300 p-5 rounded-lg mb-4;
}

.accordionBtnWrap {
  @apply flex gap-x-2 items-center;
}

.accordionIconArrow {
  @apply size-5 -rotate-90 fill-white/60 group-data-[open]:rotate-0 transition;
}

.accordionPhaseName {
  @apply text-14-500-normal text-primary-zinc-800;
}

.accordionPanel {
  @apply mt-4 flex flex-col gap-y-[30px];
  @apply transition duration-200 ease-out data-[closed]:-translate-y-1 data-[closed]:opacity-0;
}

// Product Section
.titleGroup {
  @apply text-14-500-normal text-primary-zinc-800 pb-4 border-b mb-5;
  border-color: rgba(9, 9, 11, 0.05);
}

.rowContainer {
  @apply flex flex-col gap-4;
}

.rowHead {
  @apply grid grid-cols-8 gap-5 border-b pb-3 border-primary-woodsmoke-940/5;
}

.rowHeadTitle {
  @apply col-span-2 text-14-400-normal text-primary-neutral-400;
}

.rowBody {
  @apply grid gap-5 grid-cols-8 border-b pb-4 border-primary-woodsmoke-940/5;
}

.rowBodyTitle {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-2;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.poNumber {
  @apply text-radical-red-500;
}
